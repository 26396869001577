<template>
    <section>
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">
                    <horizontalBanner :edit="edit" :text="json.data['text']" :image="json.data.image" />
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import horizontalBanner from '../../../components/element/horizontal-banner.vue'
import json from '../../../pages/dashboard/json/testText.json'
export default {
    name: 'edit-text',
    components: {
        horizontalBanner
    },
    data () {
        return {
            json: json,
            edit:true
        }
    }
}
</script>